var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "elevation": "0",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "flex-column"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-bold align-self-start"
  }, [_vm._v("Team")])]), _c('div', {
    staticClass: "d-flex flex-row pa-5"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": _vm.mdiMagnify,
      "dense": "",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-spacer'), _c('v-dialog', {
    attrs: {
      "width": "550"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-none text-body-1",
          attrs: {
            "color": "primary",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")]), _vm._v(" Invite Admin ")], 1)];
      }
    }]),
    model: {
      value: _vm.inviteDialog,
      callback: function ($$v) {
        _vm.inviteDialog = $$v;
      },
      expression: "inviteDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 primary--text font-weight-bold"
  }, [_c('v-row', {
    staticClass: "justify-space-between",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" Invite Admin ")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.inviteDialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])], 1)], 1)], 1), _c('v-card-text', [_c('v-form', {
    ref: "emailAdmin"
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": "8"
    }
  }, [_c('label', {
    attrs: {
      "for": "adminmail"
    }
  }, [_vm._v(" Email Address ")]), _c('v-text-field', {
    attrs: {
      "placeholder": "Enter email",
      "outlined": "",
      "rules": [function (v) {
        return !!v || 'Required!';
      }, function (v) {
        return /.+@.+/.test(v) || 'E-mail must be valid';
      }]
    },
    model: {
      value: _vm.adminToInvite,
      callback: function ($$v) {
        _vm.adminToInvite = $$v;
      },
      expression: "adminToInvite"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex flex-row-reverse"
  }, [_c('v-btn', {
    staticClass: "px-5 py-3 text-none text-body-1",
    attrs: {
      "color": "primary",
      "loading": _vm.inviting
    },
    on: {
      "click": _vm.validateInvAdmin
    }
  }, [_vm._v(" Send Invite ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-data-table', {
    attrs: {
      "search": _vm.search,
      "headers": _vm.teamHeaders,
      "items": _vm.teamMembers,
      "loading": `${_vm.listAdminStatus}`
    },
    scopedSlots: _vm._u([{
      key: "item.role",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(item.role) + " ")])];
      }
    }, {
      key: "item.rmaction",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.email !== _vm.currentUserEmail ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.rmTeamMember(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "danger"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiTrashCan) + " ")])], 1) : _vm._e(), _c('SmallConfirmation', {
          ref: "delconfirm"
        })];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }